'use strict';

import Loading from '../components/Loading';

const PhoneInput =  React.lazy(() => import( /* webpackChunkName: "PhoneInput" */ '../components/PhoneInput'));
const FormInput =  React.lazy(() => import( /* webpackChunkName: "FormInput" */ '../components/FormInput'));
const TimerWidget =  React.lazy(() => import( /* webpackChunkName: "TimerWidget" */ '../components/TimerWidget'));

class AuthModal extends React.Component {
    constructor(props) {
        super(props);

        this.initState = {
            mode: null,
            prevMode: null,

            scenario: null,
            email: '',
            lastname: '',
            firstname: '',
            thirdname: '',
            company: '',

            regNumber: '',
            vatNumber: '',
            address: '',

            website: '',
            contact: '',
            agreeCb: false,

            phone: '',
            phoneCode: '',
            phoneSearch: '',
            phoneComplete: false,

            errors: {},
            code: '',
            status: '',
            name: '',
            timeout: 0,
            isFetching: false,
        }
        this.state = this.initState;
        this.handleLogin = this.handleLogin.bind(this)
        this.handleSendRegistrationRequest = this.handleSendRegistrationRequest.bind(this)
        this.handleRegister = this.handleRegister.bind(this)
        this.handleChangeCode = this.handleChangeCode.bind(this)
    }

    componentDidMount() {
        const that = this;
        $(document).on('afterShow.fb', function( e, instance ) {
            if(instance.current.src === '#reactAuthModal') {
                const mode = instance.$trigger && instance.$trigger.data('mode') || instance.current.mode || 'login'
                that.setState({mode})
            }
        });
        $(document).on('afterClose.fb', function( e, instance ) {
            if(instance.current.src === '#reactAuthModal') {
                that.setState(this.initState)
            }
        });
    }

    setFetching(isFetching) {
        this.setState({isFetching});
    }

    clearErrors() {
        this.setState({errors: {}});
    }

    handleLogin() {
        const {phone, phoneCode} = this.state;
        this.setFetching(true);
        this.clearErrors();

        authAction.login({phone, phoneCode}).then(response => {
            this.setFetching(false);
            if(response.code) {
                this.setState({
                    prevMode: 'login',
                    mode: 'code',
                    status: response.code.status,
                    timeout: response.code.timeout,
                    code: response.code.code, //TODO Убрать в продакшене
                })
            } else {
                this.setState(response);
            }
        })
    }

    handleSendRegistrationRequest() {
        const {scenario, phone, phoneCode, email, lastname, firstname, thirdname, company, regNumber, vatNumber, address, website, contact} = this.state;
        this.setFetching(true);
        this.clearErrors();

        authAction.sendRegistrationRequest({scenario, phone, phoneCode, email, lastname, firstname, thirdname, company, regNumber, vatNumber, address, website, contact}).then(response => {

            this.setFetching(false);

            switch (response.status) {
                case 'status_success_sent_request':
                    this.setState({prevMode: 'register', mode: 'success'});
                    break;
                case 'status_error_validate_user':
                case 'status_error_sent_request':
                    this.setState(response);
                    break;
            }
        })
    }

    handleRegister() {
        const {scenario, phone, phoneCode, email, lastname, firstname, thirdname, company, regNumber, vatNumber, address, website, contact} = this.state;
        this.setFetching(true);
        this.clearErrors();

        authAction.register({scenario, phone, phoneCode, email, lastname, firstname, thirdname, company, regNumber, vatNumber, address, website, contact}).then(response => {
            this.setFetching(false);
            if(response.code) {
                this.setState({
                    prevMode: 'register',
                    mode: 'code',
                    status: response.code.status,
                    timeout: response.code.timeout,
                    code: response.code.code, //TODO Убрать в продакшене
                })
            } else {
                this.setState(response);
            }
        })
    }

    handleChangeCode(e) {
        this.setState({
            code: e.target.value.trim(),
            status: '',
        }, () => {
            const {prevMode, phone, phoneCode, code} = this.state;
            const {scenario, email, lastname, firstname, thirdname, company, regNumber, vatNumber, address, website, contact} = this.state;
            const data = prevMode === 'register' ? {scenario, email, lastname, firstname, thirdname, company, regNumber, vatNumber, address, website, contact, phoneCode} : {phoneCode};
            if(code.length === 4) {
                this.setFetching(true);
                authAction.checkCode({type: prevMode, phone, code, data}).then(response => {
                            this.setFetching(false);
                            if(response.status === 'success_login') {
                                this.setState({
                                    name: response.firstname
                                })
                                this.changeMode('success');
                                storageSet('userToken', response.token)
                                storageSet('userWidgetData', {
                                    token: response.token,
                                    name: `${response.firstname} ${response.lastname}`,
                                    count: response.count,
                                    hasCompany: response.hasCompany,
                                })

                                if(prevMode === 'login')
                                    ymReachGoal('sign_in')

                                if(prevMode === 'register')
                                    ymReachGoal('complete_registration')

                            } else {
                                $('#authCodeInput').blur();
                                this.setState({
                                    code: '',
                                    timeout: response.code.timeout,
                                    status: response.code.status,
                                })
                            }
                        })
            }
        })
    }

    changeMode(mode, scenario) {
        this.clearErrors();

        let state = {mode};
        if(scenario) state.scenario = scenario;

        this.setState(state)
    }

    render() {
        const {mode, scenario, code, status, timeout, name, errors, isFetching} = this.state;
        const {email, lastname, firstname, thirdname, company, regNumber, vatNumber, address, website, contact, agreeCb} = this.state;
        const {phone, phoneCode, phoneComplete} = this.state;

        const handleAction = scenario === 'agency' ? this.handleSendRegistrationRequest : this.handleRegister;

        if(mode === 'success') return <div>
            <div className="reg-modal__title">
                {scenario === 'agency' ? tMsg('Ваша заявка отправлена!') : tMsg('Добро пожаловать') + ', '}
                {scenario !== 'agency' && <br/>}
                {scenario !== 'agency' && `${name}!`}
            </div>
            {scenario !== 'agency' && <div className="reg-modal__btns">
                <a href="#" className="button button-round" data-fancybox-close>{tMsg('Полетели')}!</a>
            </div>}
            <div className="reg-modal__img">
                <img src={`${themeDir}/images/reg-img.jpg`} alt=""/>
            </div>
            <Loading active={isFetching}/>
        </div>

        if(mode === 'code') return <div>
            <div className="reg-modal__title">
                {tMsg('Введите код')}
            </div>
            <div className="reg-modal__text" dangerouslySetInnerHTML={{
                __html: tMsg('Мы отправили код подтверждения<br/>на номер <strong>{phone}</strong>').replace('{phone}', phone)
            }}/>
            <div className="reg-modal__row">
                <div className="reg-input reg-input__code">
                    {status === 'error_wrong' && <div className="reg-input__error">
                        {tMsg('Неверный код. Попробуйте ещё раз')}
                    </div>}
                    {status === 'error_attempts' && <div className="reg-input__error">
                        {tMsg('Закончились попытки. Получите новый код')}
                    </div>}
                    <input id="authCodeInput" value={code} type="text" maxLength="4" onChange={this.handleChangeCode}/>
                </div>
            </div>
            {timeout > 0 && <div className="reg-modal__timer">
                {tMsg('Получить новый код можно через')} <React.Suspense><TimerWidget seconds={timeout}/></React.Suspense>
            </div>}
            {timeout === 0 && <div className="reg-modal__timer">
                <a onClick={this.handleLogin}>{tMsg('Получить новый код')}</a>
            </div>}
            <Loading active={isFetching}/>
        </div>

        if(mode === 'select') return <div>
            <div className="reg-modal__title">
                {tMsg('Зарегистрироваться как')}
            </div>
            <div className="reg-modal__row">
                <a className="button button-gray" onClick={() => {
                    this.changeMode('register', 'client');
                    ymReachGoal('private_person')
                }}>{tMsg('Частное лицо')}</a>
            </div>
            <div className="reg-modal__row">
                <a className="button button-gray" onClick={() => {
                    this.changeMode('register', 'agency');
                    ymReachGoal('travel_agent')
                }}>{tMsg('Агент / Партнер')}</a>
            </div>
            <div className="reg-modal__row">
                <a className="button button-gray" href={langPrefix + '/b2b'} onClick={ymReachGoal('broker')}>
                    {tMsg('Авиаброкер')}
                </a>
            </div>
            <div className="reg-modal__separator">
                <span>{tMsg('или')}</span>
            </div>
            <div className="reg-modal__btns">
                <a className="button button-round-white" onClick={() => this.changeMode('login')}>
                    {tMsg2('reg.as.login')}
                </a>
            </div>
        </div>

        if(mode === 'register') return <div>
            <div className="reg-modal__title">
                {tMsg('Регистрация')}
            </div>
            <div className="reg-modal__row">
                <React.Suspense>
                    <PhoneInput
                        className={'reg-input'}
                        label={tMsg('Номер телефона')}
                        value={phone}
                        phoneCode={phoneCode}
                        error={errors.phone}
                        autoFocus={true}
                        onChange={( phone, phoneComplete ) => this.setState({phone, phoneComplete})}
                        onCodeChange={phoneCode => this.setState({phoneCode})}
                        onCompleteChange={ phoneComplete => this.setState({phoneComplete})}
                    />
                </React.Suspense>
            </div>
            <div className="reg-modal__row">
                <React.Suspense>
                    <FormInput
                        className={'reg-input'}
                        label={tMsg('Электронная почта')}
                        value={email}
                        error={errors.email}
                        onChange={email => this.setState({email})}
                    />
                </React.Suspense>
            </div>
            <div className="reg-modal__row">
                <React.Suspense>
                    <FormInput
                        className={'reg-input'}
                        label={tMsg('Фамилия')}
                        value={lastname}
                        error={errors.lastname}
                        onChange={lastname => this.setState({lastname})}
                    />
                </React.Suspense>
            </div>
            <div className="reg-modal__row">
                <React.Suspense>
                    <FormInput
                        className={'reg-input'}
                        label={tMsg('Имя')}
                        value={firstname}
                        error={errors.firstname}
                        onChange={firstname => this.setState({firstname})}
                    />
                </React.Suspense>
            </div>
            {lang === 'ru' && <div className="reg-modal__row">
                <React.Suspense>
                    <FormInput
                        className={'reg-input'}
                        label={tMsg('Отчество')}
                        value={thirdname}
                        error={errors.thirdname}
                        onChange={thirdname => this.setState({thirdname})}
                    />
                </React.Suspense>
            </div>}
            {scenario !== 'client' && <div>
                <div className="reg-modal__row">
                    <React.Suspense>
                        <FormInput
                            className={'reg-input'}
                            label={tMsg('Название компании')}
                            value={company}
                            error={errors.company}
                            onChange={company => this.setState({company})}
                        />
                    </React.Suspense>
                </div>
                {lang !== 'ru' && <div>
                    <div className="reg-modal__row">
                        <React.Suspense>
                            <FormInput
                                className={'reg-input'}
                                label={'Company registration number'}
                                value={regNumber}
                                error={errors.reg_number}
                                onChange={regNumber => this.setState({regNumber})}
                            />
                        </React.Suspense>
                    </div>
                    <div className="reg-modal__row">
                        <React.Suspense>
                            <FormInput
                                className={'reg-input'}
                                label={'VAT number'}
                                value={vatNumber}
                                error={errors.vat_number}
                                onChange={vatNumber => this.setState({vatNumber})}
                            />
                        </React.Suspense>
                    </div>
                    <div className="reg-modal__row">
                        <React.Suspense>
                            <FormInput
                                className={'reg-input'}
                                label={'Full Address'}
                                value={address}
                                error={errors.address}
                                onChange={address => this.setState({address})}
                            />
                        </React.Suspense>
                    </div>
                </div>}
            </div>}
            {errors.server && <div className="error-message">{errors.server}</div>}
            <div className="reg-checkbox">
                <React.Suspense>
                    <FormInput
                        type={'checkbox'}
                        label={{
                            text: tMsg('Я принимаю условия пользовательского соглашения и даю согласие на обработку персональных данных'),
                            replace: [
                                {
                                    text: tMsg('условия пользовательского соглашения'),
                                    href: `${langPrefix}/privacy-policy`,
                                    target: '_blank',
                                    //modal: 'termsModal',
                                    //checkOnSuccess: true,
                                },
                                {
                                    text: lang === 'ru' ? tMsg('обработку персональных данных') : tMsg('персональных данных'),
                                    href: `${langPrefix}/` + (lang === 'ru' ? 'agreement' : 'privacy-policy'),
                                    target: '_blank',
                                }
                            ]
                        }}
                        value={agreeCb}
                        onChange={agreeCb => this.setState({agreeCb})}
                    />
                </React.Suspense>
            </div>
            <div className="reg-modal__btns">
                <button className={clsx('button button-round', {disabled: !agreeCb})} onClick={handleAction}>
                    {tMsg('Продолжить регистрацию')}
                </button>
                <a className="button button-round-white" onClick={() => this.changeMode('login')}>
                    {tMsg2('reg.as.login')}
                </a>
            </div>
            <Loading active={isFetching}/>
        </div>

        if(mode === 'login') return <div>
            <div className="reg-modal__title">
                {tMsg('Вход')}
            </div>
            <div className="reg-modal__row">
                <React.Suspense>
                    <PhoneInput
                        className={'reg-input'}
                        label={tMsg('Номер телефона')}
                        value={phone}
                        phoneCode={phoneCode}
                        error={errors.phone}
                        autoFocus={true}
                        onChange={( phone ) => this.setState({phone})}
                        onCodeChange={phoneCode => this.setState({phoneCode})}
                        onCompleteChange={phoneComplete => this.setState({phoneComplete})}
                    />
                </React.Suspense>
            </div>
            <div className="reg-modal__btns">
                <button className={['button button-round', !phoneComplete && 'disabled'].join(' ')} onClick={this.handleLogin}>
                    {tMsg2('reg.as.login')}
                </button>
                <a className="button button-round-white" onClick={() => {
                    this.changeMode('select');
                    ymReachGoal('sign_up')
                }}>
                    {tMsg2('auth.register')}
                </a>
            </div>
            <Loading active={isFetching}/>
        </div>

        return null;
    }
}

const domContainer = document.querySelector('#reactAuthModal');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(AuthModal));
